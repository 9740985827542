'use client';

import { useEffect, useState } from 'react';

import {
  ClipboardIcon,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  ShareIcon,
  ToastType,
  TwitterIcon,
  useGlobalToastContext,
} from '@shared/ui-components';
import { GTM_EVENT_NAME_LEARN_SHARE, sendGtmEvent } from '@shared/utils';

import { Button } from '../button';
import { DropdownMenu } from '../dropdown-menu';

import { type ShareDropdownCmsContent } from './get-share-dropdown-cms-content';
import { type ShareDropdownProps } from './share-dropdown';
import styles from './share-dropdown.module.scss';

type ShareDropdownClientProps = ShareDropdownProps & {
  cmsContent: ShareDropdownCmsContent;
};

const handleGTMShareEvent = (label?: string) =>
  sendGtmEvent({
    eventName: GTM_EVENT_NAME_LEARN_SHARE,
    label: label || GTM_EVENT_NAME_LEARN_SHARE,
  });

export const ShareDropdownClient = ({
  cmsContent,
  dataTestId = 'share-dropdown',
  menuPlacement = 'bottom end',
  title,
  url,
}: ShareDropdownClientProps) => {
  const [hasShareSupport, setHasShareSupport] = useState<boolean | undefined>(undefined);
  const [resourceUrl, setResourceUrl] = useState('');
  const [resourceTitle, setResourceTitle] = useState('');
  const { addToast } = useGlobalToastContext();

  const showUnknownErrorToast = () =>
    addToast(
      {
        closeButtonLabel: cmsContent.close,
        description: cmsContent.somethingWentWrong,
        type: ToastType.ERROR,
      },
      { timeout: 0 }
    );

  const shareData = { title: resourceTitle, url: resourceUrl };
  const menuItems = [
    {
      children: cmsContent.shareOnLinkedIn,
      href: `https://www.linkedin.com/sharing/share-offsite/?url=${resourceUrl}`,
      Icon: <LinkedinIcon />,
      id: 'LinkedIn',
      onAction: () => handleGTMShareEvent('LinkedIn'),
      rel: 'noopener noreferrer',
      target: '_blank',
    },
    {
      children: cmsContent.shareOnX,
      href: `https://twitter.com/intent/tweet?url=${resourceUrl}`,
      Icon: <TwitterIcon />,
      id: 'Twitter',
      onAction: () => handleGTMShareEvent('Twitter'),
      rel: 'noopener noreferrer',
      target: '_blank',
    },
    {
      children: cmsContent.shareOnFacebook,
      href: `https://www.facebook.com/sharer/sharer.php?u=${resourceUrl}`,
      Icon: <FacebookIcon />,
      id: 'Facebook',
      onAction: () => handleGTMShareEvent('Facebook'),
      rel: 'noopener noreferrer',
      target: '_blank',
      withSeparatorAfter: true,
    },
    {
      children: cmsContent.copyLink,
      Icon: <ClipboardIcon />,
      id: 'clipboard',
      onAction: async () => {
        try {
          await window.navigator.clipboard.writeText(resourceUrl);
          addToast(
            {
              closeButtonLabel: cmsContent.close,
              description: cmsContent.linkCopiedSuccessfully,
              type: ToastType.SUCCESS,
            },
            { timeout: 5000 }
          );
        } catch {
          showUnknownErrorToast();
        }
      },
    },
    {
      children: cmsContent.emailResource,
      href: `mailto:?subject=${encodeURIComponent(resourceTitle)}&body=${resourceUrl}`,
      Icon: <EmailIcon />,
      id: 'email',
    },
  ];

  const handleShare = async () => {
    await window.navigator
      .share(shareData)
      .then(() => handleGTMShareEvent())
      .catch((error: Error) => {
        if (error.name !== 'AbortError') {
          showUnknownErrorToast();
        }
      });
  };

  useEffect(() => {
    setResourceUrl(url || window.location.href);
    setResourceTitle(title || window.document.title);
    setHasShareSupport(Boolean(window.navigator.share));
  }, [title, url]);

  if (hasShareSupport === undefined) {
    return <Button Icon={ShareIcon} dataTestId={dataTestId} isLoading />;
  }

  return hasShareSupport && window.navigator.canShare(shareData) ? (
    <button
      aria-label={cmsContent.shareResourceMenuTrigger}
      className={styles.trigger}
      data-testid={dataTestId}
      onClick={handleShare}
    >
      <ShareIcon />
    </button>
  ) : (
    <DropdownMenu
      TriggerIcon={<ShareIcon />}
      items={menuItems}
      menuClassName={styles.menu}
      menuPlacement={menuPlacement}
      triggerDataTestId={dataTestId}
      triggerLabel={cmsContent.shareResourceMenuTrigger}
    />
  );
};
